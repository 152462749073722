<script>
    import Select from 'svelte-select';
    import {IconSearch} from '@tabler/icons-svelte';
    import {goto} from '$app/navigation';
    import {browser} from '$app/environment';
    import {createEventDispatcher} from 'svelte';
    import AddBuildingPrompt from '$components/AddBuildingPrompt.svelte';
    import searchEntry from '$lib/stores/searchEntry';

    let filterText = '';

    const dispatch = createEventDispatcher();
    const groupBy = (item) => {
        return item.neighbourhood;
    };
    const defaultResults = [
        {
            "uuid": "2d4b7b1c-0e1e-4ebf-96d4-48152486c681",
            "name": "One Bloor",
            "originalName": "One Bloor",
            "address": "1 Bloor St E",
            "neighbourhood": "Yonge and Bloor",
            "slug": "one-bloor-1-bloor-st-e"
        },
        {
            "uuid": "c4475de0-f9e4-4ec1-9f10-b2390a2be28b",
            "name": "Charlie",
            "originalName": "Charlie",
            "address": "8 Charlotte St",
            "neighbourhood": "King West",
            "slug": "charlie-8-charlotte-st"
        },
        {
            "uuid": "122ae7f7-2c98-472b-aa4e-5ddb959eb955",
            "name": "Ice Condominiums",
            "originalName": "Ice Condominiums",
            "address": "12 York St",
            "neighbourhood": "The Core",
            "slug": "ice-condos-ice-condos-ii-12-york-st"
        },
        {
            "uuid": "c308109f-7173-46ac-adef-4a34c559a431",
            "name": "Shangri-La",
            "originalName": "Shangri-La",
            "address": "180 University Ave",
            "neighbourhood": "Queen West",
            "slug": "shangri-la-180-university-ave"
        }
    ]

    const loadOptions = async (queryString) => {
        if (!browser || !queryString || queryString === '') {
            return defaultResults;
        }
        const res = await fetch('/api/v2/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ queryString, filters: { sublocality: null } }),
        });

        const { results, success } = await res.json();
        if (!success) {
            return [];
        }

        return results;
    };

    const handleItemChange = (e) => {
        goto(`/s/${e.detail.slug}`);
    };

    const clearInput = () => {
        filterText = '';
        $searchEntry = '';
    }
</script>

<Select class='text-black'
        label='name'
        itemId='uuid'
        placeholder='Search for a Building...'
        showChevron={true}
        on:change={handleItemChange}
        on:blur={() => dispatch('searchBlur')}
        on:focus={clearInput}
        bind:filterText
        {loadOptions} {groupBy}>
    <div slot='chevron-icon'>
        <IconSearch color='black' />
    </div>
    <div slot='item' let:item class='cursor-pointer'>
        {@html item.name}{#if item.address}<span class='text-gray-500 text-xs'><span class='mx-1'>&nbsp;</span>{@html item.address}</span>{/if}
    </div>
    <div slot="selection" let:selection>
        {selection.originalName || selection.name}
    </div>
    <div slot='list-append' role='button' tabindex='0' on:click={() => { $searchEntry = filterText; }}>
        <AddBuildingPrompt allClickable='{true}' ctaOnly='{true}' class='py-3 px-5 hover:bg-gray-100' />
    </div>
</Select>